<template>
  <div>
    <b-form-group
      id="input-group-1"
      label="Câu hỏi"
      label-for="input-1"
    >
      <b-form-input
        id="input-1"
        v-model="dataForm.tieuDe"
        type="text"
        placeholder="Nhập câu hỏi"
        :autofocus="dataForm.id ? false : true"
        :disabled="true"
      />
    </b-form-group>
    <div class="custom-grid-container">
      <b-form-group
        id="input-group-1"
        label="Người hỏi"
        label-for="input-1"
        class="grap-3"
      >
        <b-form-input
          id="input-1"
          v-model="dataForm.hoTen"
          type="text"
          placeholder="Nhập người hỏi"
          :disabled="true"
        />
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Ngày hỏi"
        label-for="input-1"
        class="grap-3"
      >
        <date-picker
          v-model="formatDate"
          class="w-100"
          value-type="format"
          format="DD/MM/YYYY"
          placeholder="Chọn ngày hỏi"
          :disabled="true"
        />
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Đánh giá thường gặp"
        label-for="input-1"
        class="grap-3"
      >
        <v-select
          v-model="dataForm.tanXuat"
          class="style-chooser"
          :options="[ {id: 1, label: 'Có thường gặp'}, { id: 2, label: 'Không thường gặp' }]"
          :reduce="tx => tx.id"
          placeholder="Chọn đánh giá thường gặp"
        />
      </b-form-group>
    </div>
    <b-form-group
      id="input-group-1"
      label="Email"
      label-for="input-1"
    >
      <b-form-input
        id="input-1"
        v-model="dataForm.email"
        type="text"
        placeholder="Nhập email"
        :disabled="true"
      />
    </b-form-group>
    <b-form-group
      id="input-group-1"
      label="Nội dung câu hỏi"
      label-for="input-1"
    >
      <b-form-textarea
        id="textarea"
        v-model="dataForm.noiDung"
        placeholder="Nhập nội dung câu hỏi"
        rows="3"
        max-rows="6"
        :disabled="true"
      />
    </b-form-group>
    <b-form-group
      id="input-group-1"
      label="Trả lời"
      label-for="input-1"
      class="required"
    >
      <validation-provider
        v-slot="{ errors }"
        key="dgtg"
        :rules="{ required: true }"
        name="dgtg"
      >
        <quill-editor
          ref="dgtg"
          v-model="dataForm.cauTraLoi"
          :options="snowOption"
        />
        <span class="label-noti-validate">{{ errors[0] }}</span>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
import {
  BFormInput,
  BFormGroup,
  BFormTextarea,
} from 'bootstrap-vue'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { quillEditor } from 'vue-quill-editor'
import '@/@core/scss/vue/libs/quill.scss'
import * as dayjs from 'dayjs'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  components: {
    BFormInput,
    BFormGroup,
    BFormTextarea,
    DatePicker,
    quillEditor,
  },
  props: {
    dataForm: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      snowOption: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ header: 1 }, { header: 2 }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ direction: 'rtl' }],
          [{ size: ['small', false, 'large', 'huge'] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ font: [] }],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          ['clean'],
          ['link', 'image', 'video'],
        ],
        theme: 'snow',
        placeholder: 'Nhập nội dung câu trả lời',
      },
    }
  },
  computed: {
    formatDate() {
      return dayjs(this.dataForm.ngayHoi).format('DD/MM/YYYY')
    },
  },
}
</script>
