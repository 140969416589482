<template>
  <div>
    <b-form-group
      id="input-group-1"
      label="Tiêu đề câu hỏi"
      label-for="input-1"
      class="required"
    >
      <validation-provider
        v-slot="{ errors }"
        key="tieuDeCauHoi"
        :rules="{ required: true }"
        name="tieuDeCauHoi"
      >
        <b-form-input
          id="input-1"
          ref="tieuDeCauHoi"
          v-model="dataForm.tieuDe"
          v-trim-input
          type="text"
          placeholder="Nhập tiêu đề câu hỏi"
          required
          :autofocus="dataForm.id ? false : true"
        />
        <span class="label-noti-validate">{{ errors[0] }}</span>
      </validation-provider>
    </b-form-group>
    <b-form-group
      id="input-group-1"
      label="Nội dung câu hỏi"
      label-for="input-1"
      class="required"
    >
      <validation-provider
        v-slot="{ errors }"
        key="noiDungCauHoi"
        :rules="{ required: true }"
        name="noiDungCauHoi"
      >
        <b-form-textarea
          id="textarea"
          ref="noiDungCauHoi"
          v-model="dataForm.noiDung"
          v-trim-input
          placeholder="Nhập nội dung câu hỏi"
          rows="3"
          max-rows="6"
        />
        <span class="label-noti-validate">{{ errors[0] }}</span>
      </validation-provider>
    </b-form-group>
    <b-form-group
      id="input-group-1"
      label="Tần suất"
      label-for="input-1"
      class="required"
    >
      <validation-provider
        v-slot="{ errors }"
        key="tanSuat"
        :rules="{ required: true }"
        name="tanSuat"
      >
        <v-select
          ref="tanSuat"
          v-model="dataForm.tanXuat"
          class="style-chooser"
          placeholder="Chọn tần xuất"
          :options="[ {id: 1, label: 'Không thường gặp'}, { id: 2, label: 'Có thường gặp' }]"
          :reduce="tx => tx.id"
        />
        <span class="label-noti-validate">{{ errors[0] }}</span>
      </validation-provider>
    </b-form-group>
    <b-form-group
      id="input-group-1"
      label="Lĩnh vực"
      label-for="input-1"
      class="required"
    >
      <validation-provider
        v-slot="{ errors }"
        key="linhVuc"
        :rules="{ required: true }"
        name="linhVuc"
      >
        <v-select
          ref="linhVuc"
          v-model="dataForm.linhVucId"
          class="style-chooser"
          :options="lvCbx"
          placeholder="Chọn lĩnh vực"
          :reduce="np => np.id"
          label="name"
          :filter="onFilterSelect"
        >
          <template #no-options>
            Không có dữ liệu
          </template>
        </v-select>
        <span class="label-noti-validate">{{ errors[0] }}</span>
      </validation-provider>
    </b-form-group>
    <div class="grid-custom-input">
      <b-form-group
        id="input-group-1"
        label="Trạng thái"
        label-for="input-1"
      >
        <b-form-checkbox
          v-model="dataForm.status"
          switch
        />
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Công khai"
        label-for="input-1"
      >
        <b-form-checkbox
          v-model="dataForm.isCongKhai"
          switch
        />
      </b-form-group>
    </div>
    <div>
      <b-form-group
        id="input-group-1"
        label="File đính kèm"
        label-for="input-1"
      >
        <div class="custom-grid-xl-container">
          <div class="grap-2">
            <b-button
              variant="primary"
              class="d-flex"
              size="sm"
              :disabled="!isLoadedFile"
              @click="(e) => $refs.fileInput.click()"
            >
              <b-spinner
                v-if="!isLoadedFile"
                small
                label="Spinning"
                style="margin-right: 5px;"
              />
              Tải file
            </b-button>
            <input
              ref="fileInput"
              type="file"
              class="d-none"
              multiple
              accept="application/msword, application/vnd.ms-excel, application/pdf, .docx, .xlsx, application/x-rar-compressed, image/*"
              @change="changeFile"
            >
          </div>
          <div
            v-if="dataForm.mauDonToKhai && dataForm.mauDonToKhai.length > 0"
            class="grap-10"
          >
            <div
              v-for="(mdtk, index) in dataForm.mauDonToKhai"
              :key="index"
            >
              <span class="mr-1">
                <a
                  href="javascript:;"
                  @click="downloadFile(mdtk)"
                >{{ mdtk.tenFile }}</a>
              </span>
              <trash-2-icon
                v-b-tooltip.hover.v-secondary
                title="Xoá"
                size="16"
                class="custom-class cursor-pointer"
                @click="deleteFile(index)"
              />
            </div>
          </div>
          <div
            v-else
            class="grap-10 d-flex align-items-center"
          >
            Cho phép tải lên file .doc, .pdf, .xls, .docx, .xlsx, .zip, .rar, .jpg, .png, .bmp, .gif – Nhỏ hơn 25MB
          </div>
        </div>
        <span class="label-noti-validate">{{ errorFileSize }}</span>
      </b-form-group>
    </div>
    <div v-if="dataForm.id ? true : false">
      <div class="custom-grid-container">
        <b-form-group
          id="input-group-1"
          label="Người hỏi"
          label-for="input-1"
          class="grap-2"
        >
          <b-form-input
            id="input-1"
            v-model="dataForm.hoTen"
            type="text"
            placeholder="Nhập người hỏi"
            :disabled="true"
          />
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Ngày hỏi"
          label-for="input-1"
          class="grap-2"
        >
          <date-picker
            v-model="formatDate"
            class="w-100"
            value-type="format"
            format="DD/MM/YYYY"
            placeholder="Chọn ngày hỏi"
            :disabled="true"
          />
        </b-form-group>
      </div>
      <b-form-group
        id="input-group-1"
        label="Email"
        label-for="input-1"
      >
        <b-form-input
          id="input-1"
          v-model="dataForm.email"
          type="text"
          placeholder="Nhập email"
          :disabled="true"
        />
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Trả lời"
        label-for="input-1"
        class="required"
      >
        <validation-provider
          #default="{ errors }"
          name="gdtg"
          rules="required"
        >
          <quill-editor
            v-model="dataForm.cauTraLoi"
            :options="snowOption"
          />
          <span class="text-danger">{{ errors[0] }}</span>
        </validation-provider>
      </b-form-group>
    </div>
  </div>
</template>
<script>
import END_POINTS from '@/api/endpoints'
import {
  BFormInput,
  BFormGroup,
  VBTooltip,
  BButton,
  BSpinner,
  BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue'
import { Trash2Icon } from 'vue-feather-icons'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { quillEditor } from 'vue-quill-editor'
import '@/@core/scss/vue/libs/quill.scss'
import * as dayjs from 'dayjs'

import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import { removeDiacritical } from '@/utils/index'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormInput,
    BFormGroup,
    BButton,
    BSpinner,
    BFormCheckbox,
    BFormTextarea,
    DatePicker,
    Trash2Icon,
    quillEditor,
  },
  props: {
    dataForm: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      lvCbx: [],
      errorFileSize: null,
      isLoadedFile: true,
      snowOption: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ header: 1 }, { header: 2 }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ direction: 'rtl' }],
          [{ size: ['small', false, 'large', 'huge'] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ font: [] }],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          ['clean'],
          ['link', 'image', 'video'],
        ],
        theme: 'snow',
        placeholder: 'Nhập nội dung câu trả lời',
      },
    }
  },
  computed: {
    formatDate() {
      return dayjs(this.dataForm.ngayHoi).format('DD/MM/YYYY')
    },
  },
  created() {
    this.getDataLinhVucCbx()
  },
  methods: {
    getDataLinhVucCbx() {
      this.$axios.get(END_POINTS.LINH_VUC.COMBOBOX).then(res => {
        if (res.data?.succeeded) {
          this.lvCbx = res.data.data
        }
      })
    },
    onFilterSelect(options, search) {
      return options.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(search.trim())) >= 0)
    },
    changeFile(e) {
      this.isLoadedFile = true
      const file = Array.from(e.target.files || e.dataTransfer.files)
      const numFile = 20 - this.dataForm.mauDonToKhai.length
      if (file.length > numFile) {
        this.$toast.error('Tệp đính kèm không vượt quá 20!')
      }
      // eslint-disable-next-line prefer-const
      let fileName = []
      // eslint-disable-next-line prefer-const
      let fileNameSize = []
      // eslint-disable-next-line prefer-const
      let fileResult = []
      // eslint-disable-next-line prefer-const
      let filesAttck = []
      // eslint-disable-next-line prefer-const
      let filesNot = []
      try {
        file.forEach(item => {
          if (!this.dataForm.mauDonToKhai.find(ele => ele.tenFile.indexOf(item.name) >= 0) && item.size < 26214400) {
            if (['application/msword', 'application/vnd.ms-excel', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/x-rar-compressed', 'image/png', 'image/jpg', 'image/gif', 'image/bmp'].includes(item.type)) {
              fileResult.push(item)
            } else {
              filesNot.push(item.name)
            }
          } else if (item.size > 26214400) {
            fileNameSize.push(item.name)
          } else {
            fileName.push(item.name)
          }
        })
        fileResult = fileResult.filter((item, idx) => idx < numFile)
        filesAttck.push(...fileResult)
        if (fileName.length > 0) {
        // eslint-disable-next-line no-unused-vars
          let fileNameError = ''
          fileName.forEach(item => {
            fileNameError += `- ${item}\n`
          })
          this.$toast.error(`Tệp đính kèm bị trùng: \n ${fileNameError}`)
        }
        if (fileNameSize.length > 0) {
        // eslint-disable-next-line no-unused-vars
          let fileNameSizeError = ''
          fileNameSize.forEach(item => {
            fileNameSizeError += `- ${item}\n`
          })
          this.$toast.error(`Tệp đính kèm:\n ${fileNameSizeError} dung lượng lớn hơn 25 MB`)
        }
        if (filesNot.length > 0) {
        // eslint-disable-next-line no-unused-vars
          let filesNotError = ''
          filesNot.forEach(item => {
            filesNotError += `- ${item}\n`
          })
          this.$toast.error(`Tệp đính kèm:\n ${filesNotError} không đúng định dạng`)
        }
        if (filesAttck.length > 0) {
          // eslint-disable-next-line prefer-const
          let formData = new FormData()
          filesAttck.forEach(item => {
            formData.append('listFileData', item)
          })
          this.$axios.post(END_POINTS.UPLOAD_FILE.MULTI, formData, false).then(res => {
            if (res.data) {
              const data = res.data.map(item => ({
                tenFile: item.fileName,
                path: item.objectName,
              }))
              this.dataForm.mauDonToKhai.push(...data)
            }
            this.isLoadedFile = true
          })
        }
      } finally {
        if (filesAttck.length < 0) {
          this.isLoadedFile = true
        }
      }
    },
    deleteFile(index) {
      this.dataForm.mauDonToKhai = this.dataForm.mauDonToKhai.filter((item, idx) => idx !== index)
    },
    downloadFile(data) {
      this.$axios.post(END_POINTS.UPLOAD_FILE.DOWNLOAD, { path: data.path }).then(res => {
        if (res.data?.succeeded) {
          // eslint-disable-next-line prefer-const
          let a = document.createElement('a')
          a.href = `data:application/octet-stream;base64,${res.data.fileData}`
          a.download = res.data.fileName
          a.setAttribute('target', '_blank')
          a.click()
        }
      })
    },
  },
}
</script>
