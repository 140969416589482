<template>
  <b-card>
    <action-btn
      :selected-number="selectedNumber"
      :actions-permission="[checkQuyenThemMoi, checkQuyenXoa]"
      :actions="['createBtn', 'editorBtn', 'deleteBtn', 'reFresh']"
      @add-item="addCommom"
      @delete-item="deleteCommom"
      @refresh-page="refreshPage"
    />
    <good-table
      ref="vgTable"
      class="mt-1"
      :class="checkAllQuyen ? '' : 'fixed-column'"
      :columns="getColumns"
      :rows="rows"
      :total="total"
      :is-loading="isLoading"
      @column-filter="columnFilter"
      @selected-item="selectedItem"
      @page-change="pageChange"
    >
      <div
        slot="custom-filter"
        slot-scope="{ props }"
      >
        <span v-if="props.column.field == 'tanXuat'">
          <treeselect
            v-model="payload.tanXuat"
            v-format-v-select
            class="vtreeselect-chooser"
            :options="[ {id: 1, label: 'Không thường gặp'}, { id: 2, label: 'Thường gặp' }]"
            no-options-text="Không có dữ liệu"
            no-results-text="Không có dữ liệu"
            placeholder="Chọn tần suất"
            :clear-on-select="true"
            :before-clear-all="clearTreeSelect"
            @select="nextTickTreeSelect()"
            @deselect="nextTickTreeSelect()"
          />
        </span>
        <span v-else-if="props.column.field == 'status'">
          <treeselect
            v-model="payload.status"
            v-format-v-select
            class="vtreeselect-chooser"
            no-options-text="Không có dữ liệu"
            no-results-text="Không có dữ liệu"
            :options="optionsTrangThai"
            placeholder="Chọn trạng thái"
            :clear-on-select="true"
            :before-clear-all="clearTreeSelect"
            @select="nextTickTreeSelect()"
            @deselect="nextTickTreeSelect()"
          />
        </span>
        <span v-else-if="props.column.field == 'ngayHoi'">
          <date-picker
            v-model="ngayHoi"
            type="date"
            range
            placeholder="Chọn thời gian"
            format="DD/MM/YYYY"
            class="date-picker-table w-100"
            @input="columnFilterTable"
          />
        </span>
        <span v-else-if="props.column.field != 'STT' && props.column.field != 'actions' && props.column.field != 'ngayHoi'">
          <input
            v-model="props.column.filterOptions.filterValue"
            type="text"
            class="vgt-input"
            placeholder="Tìm kiếm"
          >
        </span>
      </div>
      <div
        slot="actions"
        slot-scope="{ props }"
      >
        <eye-icon
          v-if="checkQuyenChiTiet"
          v-b-tooltip.hover.v-secondary
          title="Chi tiết"
          size="16"
          class="custom-class cursor-pointer mr-1"
          @click="detailCommom(props.row)"
        />
        <edit-icon
          v-if="checkQuyenSua"
          v-b-tooltip.hover.v-secondary
          title="Sửa"
          size="16"
          class="custom-class cursor-pointer mr-1"
          @click="updateCommom(props.row)"
        />
        <trash-2-icon
          v-if="checkQuyenXoa"
          v-b-tooltip.hover.v-secondary
          title="Xóa"
          size="16"
          class="custom-class cursor-pointer"
          @click="deleteCommomTable(props.row)"
        />
      </div>
    </good-table>
    <common-modal
      ref="commonModal"
      :title="title"
      :size="size"
      @handle-ok="handleOk"
      @handle-focus="handleFocusError"
    >
      <component
        :is="componentName === 'XoaForm' ? '' : componentName"
        ref="componentName"
        :data-form="dataForm"
      />
      <div
        v-if="isShow"
        slot="footer"
      >
        <b-button
          variant="primary"
          :class="selected ? 'd-none' : ''"
          class="mr-1"
          size="sm"
          @click="$refs.commonModal.checkValidate()"
        >
          Lưu
        </b-button>
        <b-button
          variant="danger"
          :class="checkNone ? 'd-none' : ''"
          size="sm"
          @click="openModal"
        >
          Đóng
        </b-button>
        <b-button
          variant="danger"
          :class="checkNone ? '' : 'd-none'"
          size="sm"
          @click="$refs.commonModal.hideModal()"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
    <common-modal
      ref="commonnModal"
      title="Xác nhận"
      size="sm"
    >
      Bạn có xác nhận đóng quá trình nhập liệu này không?
      <div
        v-if="isShowFooter"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="$refs.commonModal.hideModal(),$refs.commonnModal.hideModal()"
        >
          Đồng ý
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="$refs.commonnModal.hideModal()"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
  </b-card>
</template>
<script>
import END_POINTS from '@/api/endpoints'
import {
  BCard,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import addCssSemantic from '@/mixins/mixins'
import ActionBtn from '@/modules/danh-muc/components/common/ActionBtn.vue'
import CommonModal from '@/modules/danh-muc/components/modal/CommonModal.vue'
import GoodTable from '@/components/GoodTable.vue'
import CauHoi from '@/modules/danh-muc/components/form/CauHoi.vue'
import TraLoiCauHoi from '@/modules/danh-muc/components/form/TraLoiCauHoi.vue'
import XoaForm from '@/modules/danh-muc/components/form/XoaForm.vue'
import ChiTietCauHoiForm from '@/modules/danh-muc/components/form/ChiTietCauHoiForm.vue'
import {
  EyeIcon, EditIcon, Trash2Icon, NavigationIcon,
} from 'vue-feather-icons'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { checkStatus, compareObjects } from '@/utils/index'
import { STATUS } from '@/modules/danh-muc/constants/constants'
import * as dayjs from 'dayjs'
import { PERMISSION_NAME } from '@/constants/constants'
import { hasPermission } from '@/utils/permission-utils'
import _cloneDeep from 'lodash/cloneDeep'
import Vue from 'vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BButton,
    ActionBtn,
    CommonModal,
    GoodTable,
    CauHoi,
    TraLoiCauHoi,
    XoaForm,
    ChiTietCauHoiForm,
    EditIcon,
    Trash2Icon,
    EyeIcon,
    NavigationIcon,
    DatePicker,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      title: 'Thêm mới hỏi đáp',
      optionsTrangThai: STATUS,
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Tiêu đề câu hỏi',
          field: 'tieuDe',
          width: '280px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Người hỏi',
          field: 'hoTen',
          width: 'auto',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Tần suất',
          field: 'tanXuat',
          width: '200px',
          thClass: 'text-center',
          tdClass: 'text-left',
          filterOptions: {
            enabled: true,
            placeholder: 'Tất cả',
            filterValue: null,
            filterDropdownItems: [
              { value: 1, text: 'Không thường gặp' },
              { value: 2, text: 'Thường gặp' },
            ],
          },
        },
        {
          label: 'Trạng thái',
          width: '180px',
          field: 'status',
          tdClass: 'text-left',
          thClass: 'text-center',
        },
        {
          label: 'Thời gian hỏi',
          field: 'ngayHoi',
          thClass: 'text-center',
          tdClass: 'text-center',
          type: 'date',
          width: '200px',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSSSSSSS',
          dateOutputFormat: 'dd/MM/yyyy HH:mm',
        },
        {
          label: 'Thao tác',
          field: 'actions',
          tdClass: 'text-center vertical-align-middle',
          thClass: 'text-center',
          width: '100px',
        },
      ],
      rows: [],
      selectedItems: [],
      bcCbx: [],
      dvCbx: [],
      componentName: null,
      total: 0,
      selectedNumber: 0,
      payload: {
        hoTen: null,
        dienThoai: null,
        diaChi: null,
        email: null,
        tieuDe: null,
        noiDung: null,
        listLinhVucId: [],
        listLinhVucText: null,
        cauTraLoi: null,
        tanXuat: null,
        ngayHoiTu: null,
        ngayHoiDen: null,
        pageNumber: 1,
        pageSize: 10,
      },
      ngayHoi: null,
      dataForm: {
        hoTen: null,
        dienThoai: null,
        diaChi: null,
        email: null,
        tieuDe: null,
        noiDung: null,
        linhVucId: [],
        tanXuat: null,
        cauTraLoi: null,
        status: true,
        isCongKhai: true,
      },
      beginObject: {},
      isLoading: false,
      isShowFooter: false,
      size: null,
      isShow: false,
      selected: false,
      checkNone: false,
    }
  },
  computed: {
    checkQuyenThemMoi() {
      return hasPermission([PERMISSION_NAME.HOI_DAP.THEM_MOI])
    },
    checkQuyenSua() {
      return hasPermission([PERMISSION_NAME.HOI_DAP.SUA])
    },
    checkQuyenXoa() {
      return hasPermission([PERMISSION_NAME.HOI_DAP.XOA])
    },
    checkQuyenChiTiet() {
      return hasPermission([PERMISSION_NAME.HOI_DAP.CHI_TIET])
    },
    checkAllQuyen() {
      return [this.checkQuyenSua, this.checkQuyenXoa, this.checkQuyenChiTiet].every(item => item === false)
    },
    getColumns() {
      return this.checkAllQuyen ? this.columns.filter(item => item.field !== 'actions') : this.columns
    },
  },
  created() {
    this.getDataCauHoi()
  },
  methods: {
    getDataCauHoi() {
      this.isLoading = false
      try {
        this.$axios.post(END_POINTS.CAU_HOI.DS, this.payload, false).then(res => {
          if (res.data?.succeeded) {
            this.rows = res.data.data
            this.total = res.data.totalCount
          }
        })
      } finally {
        setTimeout(() => {
          this.isLoading = true
        }, 2000)
      }
    },
    clearTreeSelect() {
      setTimeout(() => {
        this.nextTickTreeSelect()
      }, 200)
      return true
    },
    nextTickTreeSelect() {
      this.$nextTick(() => {
        this.getDataCauHoi()
      })
    },
    refreshPage() {
      this.payload = {
        hoTen: null,
        dienThoai: null,
        diaChi: null,
        email: null,
        tieuDe: null,
        noiDung: null,
        listLinhVucId: [],
        listLinhVucText: null,
        cauTraLoi: null,
        tanXuat: null,
        ngayHoiTu: null,
        ngayHoiDen: null,
        pageNumber: 1,
        pageSize: 10,
      }
      this.ngayHoi = null
      this.$refs.vgTable.$refs.vbTables.reset()
      this.getDataCauHoi()
    },
    addCommom() {
      this.selected = false
      this.checkNone = false
      this.isShow = true
      this.title = 'Thêm mới câu hỏi'
      this.componentName = 'CauHoi'
      this.size = 'lg'
      this.dataForm = {
        hoTen: null,
        dienThoai: null,
        diaChi: null,
        email: null,
        tieuDe: null,
        noiDung: null,
        linhVucId: [],
        mauDonToKhai: [],
        tanXuat: null,
        cauTraLoi: null,
        status: true,
        isCongKhai: true,
      }
      this.beginObject = _cloneDeep(this.dataForm)
      this.$refs.commonModal.showModal()
    },
    selectedItem(data) {
      this.selectedItems = data
      this.selectedNumber = data.length
    },
    handleOk() {
      if (this.componentName === 'XoaForm') {
        const payload = {
          listId: this.selectedItems.map(item => item.id),
        }
        this.$axios.delete(END_POINTS.CAU_HOI.XOA, payload).then(res => {
          if (res.data?.succeeded) {
            if (this.selectedItems.length >= this.rows.length && this.payload.pageNumber > 1) {
              this.payload.pageNumber -= 1
              this.$refs.vgTable.resetCurrentPage(this.payload.pageNumber)
            }
            this.getDataCauHoi()
            this.$refs.commonModal.hideModal()
            if (res.data?.data) {
              res.data.data.forEach(item => {
                if (!item.succeeded) {
                  this.$toast.error(item.message)
                }
              })
            }
          }
        })
      } else if (this.componentName === 'CauHoi' || this.componentName === 'TraLoiCauHoi') {
        this.dataForm.mauDonToKhai = this.dataForm.mauDonToKhai || []
        if (this.dataForm?.id) {
          this.$axios.put(END_POINTS.CAU_HOI.SUA, this.$trimObject(this.dataForm), false).then(res => {
            if (checkStatus(res)) {
              this.getDataCauHoi()
              this.$refs.commonModal.hideModal()
            } else {
              Vue.$toast.error(res.data.message)
            }
          })
        } else {
          this.$axios.post(END_POINTS.CAU_HOI.THEM, this.$trimObject(this.dataForm), false).then(res => {
            if (checkStatus(res)) {
              this.getDataCauHoi()
              this.$refs.commonModal.hideModal()
            } else {
              Vue.$toast.error(res.data.message)
            }
          })
        }
      }
    },
    updateCommom(cauHoi) {
      this.isLoading = false
      try {
        this.$axios.get(END_POINTS.CAU_HOI.CHI_TIET, {
          id: cauHoi.id,
        }, false).then(res => {
          if (res.data?.succeeded) {
            const { data } = res.data
            this.selected = false
            this.isShow = true
            this.checkNone = false
            this.title = 'Cập nhật câu hỏi'
            this.componentName = 'CauHoi'
            this.size = 'lg'
            this.dataForm = {
              id: data.id,
              tieuDe: data.tieuDe,
              noiDung: data.noiDung,
              tanXuat: data.tanXuat,
              linhVucId: data.linhVucId,
              cauTraLoi: data.cauTraLoi,
              status: data.status,
              hoTen: data.hoTen,
              mauDonToKhai: data.mauDonToKhai || [],
              dienThoai: data.dienThoai,
              diaChi: data.diaChi,
              email: data.email,
              isCongKhai: data.isCongKhai,
            }
            this.$nextTick(() => {
              this.beginObject = _cloneDeep(this.dataForm)
              this.$refs.commonModal.showModal()
            })
          }
        })
      } finally {
        setTimeout(() => {
          this.isLoading = true
        }, 2000)
      }
    },
    deleteCommomTable(data) {
      this.selected = false
      this.isShow = false
      this.title = 'Xác nhận'
      this.dataForm = 'Bạn có xác nhận xóa hành động này không?'
      this.componentName = 'XoaForm'
      this.size = 'md'
      this.selectedItems = [data]
      this.$refs.commonModal.showModal()
    },
    deleteCommom() {
      this.selected = false
      this.isShow = false
      this.title = 'Xác nhận'
      this.dataForm = 'Bạn có xác nhận xóa hành động này không?'
      this.componentName = 'XoaForm'
      this.size = 'md'
      this.$refs.commonModal.showModal()
    },
    pageChange() {
      const data = this.$refs.vgTable.getCurrentPage()
      this.payload = {
        ...this.payload,
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
      }
      this.$axios.post(END_POINTS.CAU_HOI.DS, this.payload, false).then(res => {
        if (res.data?.succeeded) {
          this.rows = res.data.data
        }
      })
    },
    columnFilter(data) {
      this.payload = {
        tieuDe: data.tieuDe ? data.tieuDe.replace(/\s+/g, ' ').trim() : '',
        hoTen: data.hoTen ? data.hoTen.replace(/\s+/g, ' ').trim() : '',
        dienThoai: null,
        diaChi: null,
        email: null,
        noiDung: null,
        listLinhVucId: [],
        listLinhVucText: null,
        cauTraLoi: null,
        tanXuat: this.payload.tanXuat,
        status: this.payload.status,
        ngayHoiTu: this.ngayHoi && this.ngayHoi[0] ? dayjs(this.ngayHoi[0]).format('YYYY-MM-DD') : null,
        ngayHoiDen: this.ngayHoi && this.ngayHoi[1] ? dayjs(this.ngayHoi[1]).format('YYYY-MM-DD') : null,
        pageNumber: 1,
        pageSize: this.payload.pageSize,
      }
      this.$axios.post(END_POINTS.CAU_HOI.DS, this.payload, false).then(res => {
        if (res.data?.succeeded) {
          this.rows = res.data.data
          this.total = res.data.totalCount
          this.$refs.vgTable.resetCurrentPage(1)
        }
      })
    },
    columnFilterTable() {
      this.$nextTick(() => {
        this.$refs.vgTable.onColumnFilter()
      })
    },
    addAnswerCommom(data) {
      this.componentName = 'TraLoiCauHoi'
      this.title = 'Trả lời câu hỏi'
      this.size = 'lg'
      this.isShow = true
      this.selected = false
      this.checkNone = false
      this.dataForm = {
        id: data.id,
        tieuDe: data.tieuDe,
        noiDung: data.noiDung,
        tanXuat: data.tanXuat,
        linhVucId: data.linhVucId,
        mauDonToKhai: data.mauDonToKhai || [],
        cauTraLoi: data.cauTraLoi,
        status: data.status,
        hoTen: data.hoTen,
        dienThoai: data.dienThoai,
        diaChi: data.diaChi,
        email: data.email,
      }
      this.$refs.commonModal.showModal()
    },
    detailCommom(cauHoi) {
      this.isLoading = false
      try {
        this.$axios.get(END_POINTS.CAU_HOI.CHI_TIET, {
          id: cauHoi.id,
        }, false).then(res => {
          if (res.data?.succeeded) {
            const { data } = res.data
            this.selected = true
            this.checkNone = true
            this.isShow = true
            this.componentName = 'ChiTietCauHoiForm'
            this.title = 'Chi tiết câu hỏi'
            this.size = 'lg'
            this.dataForm = {
              id: data.id,
              tieuDe: data.tieuDe,
              noiDung: data.noiDung,
              tanXuat: data.tanXuat,
              linhVucId: data.linhVucId,
              mauDonToKhai: data.mauDonToKhai,
              cauTraLoi: data.cauTraLoi,
              status: data.status,
              hoTen: data.hoTen,
              dienThoai: data.dienThoai,
              diaChi: data.diaChi,
              email: data.email,
            }
            this.$refs.commonModal.showModal()
          }
        })
      } finally {
        setTimeout(() => {
          this.isLoading = true
        }, 2000)
      }
    },
    onSelectRow(data) {
      this.$isDisabelBtn = data.length
    },
    openModal() {
      if (compareObjects(this.beginObject, this.dataForm)) {
        this.$refs.commonModal.hideModal()
        this.$refs.commonnModal.hideModal()
      } else {
        this.$refs.commonnModal.showModal()
        this.isShowFooter = true
      }
    },
    handleFocusError(first) {
      if (first) {
        const field = first[0]
        if (['tanSuat', 'linhVuc'].includes(field)) {
          this.$refs.componentName.$refs[field].$el.querySelector('input').focus()
        } else if (['tieuDeCauHoi', 'noiDungCauHoi'].includes(field)) {
          this.$refs.componentName.$refs[field].focus()
        }
      }
    },
  },
}
</script>
