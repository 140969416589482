<template>
  <div>
    <b-form-group
      id="input-group-1"
      label="Câu hỏi"
      label-for="input-1"
    >
      <b-form-input
        id="input-1"
        v-model="dataForm.tieuDe"
        type="text"
        placeholder="Nhập câu hỏi"
        :autofocus="dataForm.id ? false : true"
        :disabled="true"
      />
    </b-form-group>
    <div class="custom-grid-container">
      <b-form-group
        id="input-group-1"
        label="Người hỏi"
        label-for="input-1"
        class="grap-2"
      >
        <b-form-input
          id="input-1"
          v-model="dataForm.hoTen"
          type="text"
          placeholder="Nhập người hỏi"
          :disabled="true"
        />
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Thời gian hỏi"
        label-for="input-1"
        class="grap-2"
      >
        <date-picker
          v-model="formatDate"
          class="w-100"
          value-type="format"
          format="DD/MM/YYYY"
          placeholder="Chọn ngày hỏi"
          :disabled="true"
        />
      </b-form-group>
    </div>
    <b-form-group
      id="input-group-1"
      label="Email"
      label-for="input-1"
    >
      <b-form-input
        id="input-1"
        v-model="dataForm.email"
        type="text"
        placeholder="Nhập email"
        :disabled="true"
      />
    </b-form-group>
    <b-form-group
      id="input-group-1"
      label="Nội dung câu hỏi"
      label-for="input-1"
    >
      <b-form-textarea
        id="textarea"
        v-model="dataForm.noiDung"
        placeholder="Nhập nội dung câu hỏi"
        rows="3"
        max-rows="6"
        :disabled="true"
      />
    </b-form-group>
    <b-form-group
      id="input-group-1"
      label="File đính kèm"
      label-for="input-1"
    >
      <div
        v-for="file in dataForm.mauDonToKhai"
        :key="file.path"
        class="p-1 file-wrap mb-1"
      >
        <a
          :href="baseURL+file.path"
          download
          target="_blank"
          class="d-flex justify-content-between"
        >
          <div>{{ file.tenFile }}</div>
          <feather-icon icon="DownloadIcon" />
        </a>
      </div>
    </b-form-group>
    <b-form-group
      id="input-group-1"
      label="Trả lời"
      :class="dataForm.cauTraLoi ? '' : 'd-none'"
      label-for="input-1"
    >
      <div
        class="text-tra-loi"
        v-html="dataForm.cauTraLoi"
      />
    </b-form-group>
  </div>
</template>
<script>
import {
  BFormInput,
  BFormGroup,
  BFormTextarea,
} from 'bootstrap-vue'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import '@/@core/scss/vue/libs/quill.scss'
import * as dayjs from 'dayjs'
import { DOMAIN } from '@/constants/constants'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  components: {
    BFormInput,
    BFormGroup,
    BFormTextarea,
    DatePicker,
  },
  props: {
    dataForm: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      baseURL: DOMAIN,
    }
  },
  computed: {
    formatDate() {
      return dayjs(this.dataForm.ngayHoi).format('DD/MM/YYYY')
    },
  },
}
</script>
<style scoped>
.text-tra-loi{
  padding:15px;
  border-radius:5px;
  background:#EFEFEF;
}
.file-wrap {
  background-color: #efefef;
}
</style>
