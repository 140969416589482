<template>
  <hoi-dap />
</template>
<script>
import HoiDap from '@/modules/danh-muc/components/pages/danh-muc/tien-ich/HoiDap.vue'

export default {
  components: {
    HoiDap,
  },
}
</script>
